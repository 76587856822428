import React from "react";
import instagramIcon from "../icons/instagramIcon.svg"; // Corrected the import for instagramIcon
import facebookIcon from "../icons/facebookIcon.svg"; // Corrected the import for facebookIcon
import { Container, Row, Col } from "react-bootstrap";

function Footer() {
  return (
    <React.Fragment>
      <Container className="bg-dark" id="template_footer">
        <Row>
          <Col md={4} className="pt-5">
            <h2 className="h5 text-light border-bottom pb-3 border-light">
              SUNRISE TEXTILES (HK)
            </h2>
            <ul className="list-unstyled text-light footer-link-list">
              <li>
                <i className="fas fa-map-marker-alt fa-fw"></i>
                FLAT F1, 16/F, Mirador Mansion, 54-56 Nathan Road, Tsim Sha Tsui, Kowloon, Hong Kong
              </li>
              <li>
                <i className="fa fa-phone fa-fw"></i>
                <a
                  className="text-light text-decoration-none"
                  href="tel:(852) 2301 3536"
                >
                  (852) 2301 3536  
                </a>
                <a
                  className="text-light text-decoration-none"
                  href="tel:(852) 2301 3537"
                >
                   / 2301 3537
                </a>
              </li>
              <li>
                <i className="fa fa-fax fa-fw"></i>
                FAX: (852) 2301 3538
              </li>
              <li>
                <i className="fa fa-envelope fa-fw"></i>
                <a
                  className="text-light text-decoration-none"
                  href="https://api.whatsapp.com/send?phone=85262710972"
                >
                  Whatsapp: (852) 62710972
                </a>
              </li>
              <li>
                <i className="fa fa-envelope fa-fw"></i>
                <a
                  className="text-light text-decoration-none"
                  href="mailto:mike@sunrisetextileshk.com"
                >
                  mike@sunrisetextileshk.com
                </a>
              </li>
            </ul>
          </Col>

          <Col md={4} className="pt-5">
            <h2 className="h5 text-light border-bottom pb-3 border-light">
              Fabrics
            </h2>
            <ul className="list-unstyled text-light footer-link-list">
              <li>
                <a
                  className="text-light text-decoration-none"
                  href="/OurCollection"
                >
                  Our Collection
                </a>
              </li>
              <li>
                <a
                  className="text-light text-decoration-none"
                  href="/NewArrivals"
                >
                  New Arrivals
                </a>
              </li>
              <li>
                <a className="text-light text-decoration-none" href="/Sale">
                  Sale
                </a>
              </li>
            </ul>
          </Col>

          <Col md={4} className="pt-5">
            <h2 className="h5 text-light border-bottom pb-3 border-light">
              Further Info
            </h2>
            <ul className="list-unstyled text-light footer-link-list">
              <li>
                <a className="text-light text-decoration-none" href="/AboutUs">
                  About Us
                </a>
              </li>
              <li>
                <a className="text-light text-decoration-none" href="/Contact">
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  className="text-light text-decoration-none"
                  href="http://facebook.com/"
                  target="_blank" // Added to open the link in a new window
                  rel="noopener noreferrer" // Security measure for opening links in a new tab
                >
                  <img
                    src={facebookIcon}
                    alt="Facebook"
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "10px",
                    }}
                  />
                </a>
                <a
                  className="text-light text-decoration-none"
                  href="https://www.instagram.com/"
                  target="_blank" // Added to open the link in a new window
                  rel="noopener noreferrer" // Security measure for opening links in a new tab
                >
                  <img
                    src={instagramIcon}
                    alt="Instagram"
                    style={{ width: "24px", height: "24px" }}
                  />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container fluid className="w-100 bg-black py-1">
        <Row className="pt-1">
          <Col xs={12}>
            <p className="text-left text-light small">
              Copyright &copy; 2021 SUNRISE TEXTILES (HK) LTD. | Developed By
              RJ
            </p>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Footer;
