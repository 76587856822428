import { db } from "../../config/firebase-config";
import { doc, deleteDoc } from "firebase/firestore";

export const deleteproduct = async (id, productName) => {
  const reference = doc(db, "OurCollection", id);
  await deleteDoc(reference)
    .then(
      alert(
        productName + " is deleted from the stock, please refresh the page "
      )
    )
    .catch((err) => {
      console.log(err);
    });
};
