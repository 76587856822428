import React, { useMemo, useState } from "react";
import { Modal, Row, Col, ListGroup, Table, Button, DropdownButton, Dropdown, Form } from "react-bootstrap";
import { db } from "../../config/firebase-config";
import { doc, updateDoc } from "firebase/firestore";

const ManageBooks = ({ show, handleCloseModal, productlist }) => {
    const [selectedBook, setSelectedBook] = useState(null);
    const [newBookTitle, setNewBookTitle] = useState("");

    const distinctBooks = useMemo(() => {
        if (!productlist) return [];
        const bookTitles = productlist.map(book => book.book ? book.book : "undefined");
        const uniqueBooks = [...new Set(bookTitles)];
        return uniqueBooks.sort();
    }, [productlist]);

    const handleSelectBook = (book) => {
        setSelectedBook(book);
    };

    const updateProductBook = (id, bookTitle) => {
        const updateproductbook = doc(db, "OurCollection", id);
        updateDoc(updateproductbook, {
            book: bookTitle,
        })
        .then(() => {
            alert("Product is updated for book: " + bookTitle);
        })
        .catch((error) => {
            console.error("Error updating book:", error.message);
        });
    };

    const handleChangeBook = (id, newBook) => {
        const productIndex = productlist.findIndex(product => product.id === id);
        if (productIndex !== -1) {
            const updatedProductList = [...productlist];
            updatedProductList[productIndex].book = newBook;
            updateProductBook(id, newBook);
            // Assuming there's a method to update the product list in the parent component or in global state
            // updateProductList(updatedProductList); 
        }
    };

    const handleAddNewBook = () => {
        if (newBookTitle && !distinctBooks.includes(newBookTitle)) {
            distinctBooks.push(newBookTitle);
            setNewBookTitle("");
        }
    };

    return (
        <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Manage Books</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={12} md={8} lg={6}>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter new book title" 
                            value={newBookTitle} 
                            onChange={(e) => setNewBookTitle(e.target.value)} 
                            style={{ marginBottom: '10px', width: '100%' }}
                        />
                    </Col>
                    <Col sm={12} md={4} lg={6}>
                       <Button variant="primary" style={{ float: 'right', marginBottom: '10px' }} onClick={handleAddNewBook}>
                            Add New Book
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6} lg={4}>
                        <ListGroup>
                            {distinctBooks.map((title, index) => (
                                <ListGroup.Item 
                                    key={index} 
                                    action 
                                    onClick={() => handleSelectBook(title)}
                                    active={selectedBook === title}
                                >
                                    {title}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                    <Col sm={12} md={6} lg={8}>
                        {selectedBook && (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Product SKU</th>
                                        <th>Change Book</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productlist.filter(book => book.book === selectedBook || (selectedBook === "undefined" && !book.book)).map((item, idx) => (
                                        <tr key={idx}>
                                            <td>{item.productSKU}</td>
                                            <td>
                                                <DropdownButton id="dropdown-basic-button" title="Change Book">
                                                    {distinctBooks.map((bookTitle) => (
                                                        <Dropdown.Item key={bookTitle} onClick={() => handleChangeBook(item.id, bookTitle)}>
                                                            {bookTitle}
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default ManageBooks;
