import React from "react";
import { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { db } from "../../config/firebase-config";
import { getDocs, collection } from "firebase/firestore";

export default function MainCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const [MainCarouselImg, setMainCarouselImg] = useState([]);

  const fetchPost = async () => {
    await getDocs(collection(db, "MainCarousel")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setMainCarouselImg(newData);
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);

  return (
    <div className="carousel-container">
      <Carousel
        fade
        interval={1000}
        activeIndex={index}
        onSelect={handleSelect}
        style={{ overflow: "hidden", width: "100%", height: "360px" }}
      >
        {MainCarouselImg.map((image) => (
          <Carousel.Item key={image.name}>
            <img
              className="carousel-image"
              src={image.imgUrl}
              alt={image.name}
              style={{ width: "100%" }}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}
