import React, { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [itemsInCart, setItemsInCart] = useState(() => {
    const sessionData = sessionStorage.getItem("cartItems");
    return sessionData ? JSON.parse(sessionData) : [];
  });

  useEffect(() => {
    sessionStorage.setItem("cartItems", JSON.stringify(itemsInCart));
  }, [itemsInCart]);

  const addItemToCart = (product, yardage) => {
    const newItem = {
      name: product.productName,
      sku: product.productSKU,
      imgurl: product.imgUrl1,
      yardage: yardage,
      price: product.productPrice,
    };
    setItemsInCart((previousItems) => [...previousItems, newItem]);
  };

  const updateItemYardage = (index, newYardage) => {
    setItemsInCart((currentItems) =>
      currentItems.map((item, idx) => idx === index ? { ...item, yardage: newYardage } : item)
    );
  };

  const removeItemFromCart = (index) => {
    setItemsInCart((currentItems) =>
      currentItems.filter((item, idx) => idx !== index)
    );
  };

  const emptyCart = () => {
    setItemsInCart([]);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems: itemsInCart,
        addToCart: addItemToCart,
        updateYardage: updateItemYardage,
        removeItem: removeItemFromCart,
        clearCart: emptyCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
