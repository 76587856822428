import { where } from "firebase/firestore";
import React, { useEffect, useState, useCallback } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../config/firebase-config";
import { ProductViewCard } from "../components/productcard/ProductViewCard";

export const ProductView = (props) => {
  const { productSKU } = useParams();

  const [productinfo, setproductinfo] = useState([]);

  const fetchproduct = useCallback(async () => {
    const querryBuilder = query(
      collection(db, "OurCollection"),
      where("productSKU", "==", productSKU)
    );

    await getDocs(querryBuilder)
      .then((querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setproductinfo(newData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [productSKU]); // dependency on productSKU

  useEffect(() => {
    fetchproduct();
  }, [fetchproduct]); // dependency on fetchproduct

  return (
    <Container key={"product-View"} style={{ minHeight: "80vh" }}>
      <br />

      {productinfo.map((product) =>
        product.productSKU > 0 ? (
          <ProductViewCard key={product.productSKU} product={product} />
        ) : null
      )}
    </Container>
  );
};
