import React, { useEffect, useState } from "react";
import { db } from "../config/firebase-config";
import { collection, query, getDocs, orderBy, limit } from "firebase/firestore";
import { Product } from "../components/productcard/ProductCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

export const NewArrivals = () => {
  const [queryProduct, setqueryProduct] = useState([]);

  const fetchproductstop4 = async () => {
    const querryBuilder = query(
      collection(db, "OurCollection"),
      orderBy("created", "desc"),
      limit(30)
    );

    await getDocs(querryBuilder).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setqueryProduct(newData);
    });
  };

  useEffect(() => {
    fetchproductstop4();
  }, []);

  return (
    <Container
      className="Grid-View bg-light"
      key={"new-Arrival-Container"}
      style={{ minHeight: "90vh", maxWidth: '15in' , minWidth:'6in' }}
    >
      <Row key={"header-Row"}>
        <h2>New Arrivals</h2>
      </Row>
      <Row className="Grid-View-Row" key={"data-Row"}>
        {queryProduct.map((productitem) => (
          <Col
            key={("productitem", productitem.productSKU)}
            xs={{ span: 6 }}
            sm={{ span: 5 }}
            md={{ span: 4 }}
            lg={{ span: 3 }}
            xl={{ span: 2 }}
          >
            <Product key={productitem.productSKU} product={productitem} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
