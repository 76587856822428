import React, { useState, useEffect, useMemo, useCallback } from "react";
import { db } from "../config/firebase-config";
import { getDocs, collection } from "firebase/firestore";
import { Product } from "../components/productcard/ProductCard";
import { Container, Row, Col, Form, Accordion, Button, Pagination } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

export const OurCollection = () => {
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState({ color: "", fabricPattern: "", easyIron: "", fabric: "", book: "", price: "", productCategory: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const updatedFilter = {};
    for (const key of ['color', 'fabricPattern', 'easyIron', 'fabric', 'book','price', 'productCategory']) {
      const value = params.get(key);
      if (value) {
        updatedFilter[key] = value;
      }
    }
    setFilter(prev => ({ ...prev, ...updatedFilter }));
  }, [location]);

  const fetchPosts = async () => {
    const querySnapshot = await getDocs(collection(db, "OurCollection"));
    const newData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    const sortedData = newData.sort((a, b) => a.productSKU.localeCompare(b.productSKU));
    setProducts(sortedData);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleFilterChange = useCallback((e) => {
    const { name, value, checked } = e.target;
    const newValue = checked ? value : "";
    setFilter(prev => ({ ...prev, [name]: newValue }));
    const params = new URLSearchParams(location.search);
    if (newValue) {
      params.set(name, newValue);
    } else {
      params.delete(name);
    }
    navigate({ search: params.toString() });
  }, [location, navigate]);

  const filteredProducts = useMemo(() => products.filter(product => 
      (filter.color === "" || product.productColor === filter.color) &&
      (filter.fabricPattern === "" || product.productFabricPattern === filter.fabricPattern) &&
      (filter.fabric === "" || product.productFabric === filter.fabric) &&
      (filter.book === "" || product.book === filter.book || product.book === undefined) &&
      ((filter.easyIron === "" || (filter.easyIron === "Easy Iron" && product.EasyIronOption === "true")) ||
      (filter.easyIron === "" || (filter.easyIron === "Non-Easy Iron" && product.EasyIronOption === "false"))) &&
      (filter.price === "" || product.productPrice === filter.price) &&
      (filter.productCategory === "" || product.productCategory === filter.productCategory)
    ), [products, filter]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredProducts.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

   const books = useMemo(() => {
    const allBooks = filteredProducts.map(product => product.book || "Others");
    const uniqueBooks = [...new Set(allBooks)];
    return uniqueBooks.sort();
    }, [filteredProducts]);

    // Extract distinct colors from products
  const colors = useMemo(() => {
    const allColors = filteredProducts.map(product => product.productColor);
    return [...new Set(allColors)].sort();
  }, [filteredProducts]);

  // Extract distinct fabric patterns from products
  const fabricPatterns = useMemo(() => {
    const allPatterns = filteredProducts.map(product => product.productFabricPattern);
    return [...new Set(allPatterns)].sort();
  }, [filteredProducts]);

  // Extract distinct fabrics from products
  const fabrics = useMemo(() => {
    const allFabrics = filteredProducts.map(product => product.productFabric);
    return [...new Set(allFabrics)].sort();
  }, [filteredProducts]);

    // Extract distinct prices from products and sort them numerically
  const prices = useMemo(() => {
    const allPrices = filteredProducts.map(product => product.productPrice);
    return [...new Set(allPrices)].sort((a, b) => a - b);
  }, [filteredProducts]);

  // Extract distinct Easy Iron options from all products, not just filtered products
  const easyIronOptions = useMemo(() => {
    const allOptions = filteredProducts.map(product => product.EasyIronOption);
    return [...new Set(allOptions)].sort();
  }, [filteredProducts]);

  // Extract distinct product categories from products
  const productCategories = useMemo(() => {
    const allCategories = filteredProducts.map(product => product.productCategory);
    return [...new Set(allCategories)].sort();
  }, [filteredProducts]);

  const displayedColors = filter.color ? [filter.color] : colors;
  const displayedPatterns = filter.fabricPattern ? [filter.fabricPattern] : fabricPatterns;
  const displayedFabrics = filter.fabric ? [filter.fabric] : fabrics;
  const displayedEasyIronOptions = filter.easyIron ? [filter.easyIron] : easyIronOptions;
  const displayedBooks = filter.book ? [filter.book] : books;
  const displayedPrices = filter.price ? [filter.price] : prices;
  const displayedProductCategories = filter.productCategory ? [filter.productCategory] : productCategories;

  return (
    <Container className="py-3" style={{ maxWidth: '15in' , minWidth:'4in'  }}>
      <Row>
        <Col lg={2} md={4} style={{paddingLeft:"1rem"}}>
          <h1 className="h2 pb-4">Filter</h1>
          {Object.entries(filter).map(([key, value]) => 
            value && (
              <div className="mb-2" key={key}>
                <Button variant="secondary">
                  {`${value}`}
                  <span className="badge bg-light text-dark ms-2" onClick={() => {
                    const newFilter = { ...filter, [key]: "" };
                    setFilter(newFilter);
                    const params = new URLSearchParams(location.search);
                    params.delete(key);
                    navigate({ search: params.toString() });
                  }}>&times;</span>
                </Button>
              </div>
            )
          )}
          <Accordion defaultActiveKey="0" >
            <Accordion.Item eventKey="0">
              <Accordion.Header>Colors</Accordion.Header>
              <Accordion.Body>
                {displayedColors.map((color, index) => (
                  <Form.Check key={color + index} className="mb-2">
                    <Form.Check.Input
                      type="checkbox"
                      value={color}
                      onChange={handleFilterChange}
                      checked={filter.color === color}
                      id={color}
                      name="color"
                    />
                    <Form.Check.Label htmlFor={color}>
                      {color}
                    </Form.Check.Label>
                  </Form.Check>
                ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Category</Accordion.Header>
              <Accordion.Body>
                {displayedProductCategories.map((category, index) => (
                  <Form.Check key={category + index} className="mb-2">
                    <Form.Check.Input
                      type="checkbox"
                      value={category}
                      onChange={handleFilterChange}
                      checked={filter.productCategory === category}
                      id={category}
                      name="productCategory"
                    />
                    <Form.Check.Label htmlFor={category}>
                      {category}
                    </Form.Check.Label>
                  </Form.Check>
                ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Fabrics</Accordion.Header>
              <Accordion.Body>
                {displayedFabrics.map((fabric, index) => (
                  <Form.Check key={fabric + index} className="mb-2">
                    <Form.Check.Input
                      type="checkbox"
                      value={fabric}
                      onChange={handleFilterChange}
                      checked={filter.fabric === fabric}
                      id={fabric}
                      name="fabric"
                    />
                    <Form.Check.Label htmlFor={fabric}>
                      {fabric}
                    </Form.Check.Label>
                  </Form.Check>
                ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Fabric Patterns</Accordion.Header>
              <Accordion.Body>
                {displayedPatterns.map((pattern, index) => (
                  <Form.Check key={pattern + index} className="mb-2">
                    <Form.Check.Input
                      type="checkbox"
                      value={pattern}
                      onChange={handleFilterChange}
                      checked={filter.fabricPattern === pattern}
                      id={pattern}
                      name="fabricPattern"
                    />
                    <Form.Check.Label htmlFor={pattern}>
                      {pattern}
                    </Form.Check.Label>
                  </Form.Check>
                ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Easy Iron Options</Accordion.Header>
              <Accordion.Body>
                {displayedEasyIronOptions.map((option, index) => (
                  <Form.Check key={option + index} className="mb-2">
                    <Form.Check.Input
                      type="checkbox"
                      value={option === "true" ? "Easy Iron" : "Non-Easy Iron"}
                      onChange={handleFilterChange}
                      checked={filter.easyIron === (option === "true" ? "Easy Iron" : "Non-Easy Iron")}
                      id={option}
                      name="easyIron"
                    />
                    <Form.Check.Label htmlFor={option}>
                      {option === "true" ? "Easy Iron" : "Non-Easy Iron"}
                    </Form.Check.Label>
                  </Form.Check>
                ))}
              </Accordion.Body>
            </Accordion.Item>
             <Accordion.Item eventKey="5">
              <Accordion.Header>Books</Accordion.Header>
              <Accordion.Body>
                {displayedBooks.map((book, index) => (
                  <Form.Check key={book + index} className="mb-2">
                    <Form.Check.Input
                      type="checkbox"
                      value={book}
                      onChange={handleFilterChange}
                      checked={filter.book === book}
                      id={book}
                      name="book"
                    />
                    <Form.Check.Label htmlFor={book}>
                      {book}
                    </Form.Check.Label>
                  </Form.Check>
                ))}
              </Accordion.Body>
            </Accordion.Item>
             <Accordion.Item eventKey="6">
             <Accordion.Header>Fabric Price</Accordion.Header>
              <Accordion.Body>
                {displayedPrices.map((price, index) => (
                  <Form.Check key={price + index} className="mb-2">
                    <Form.Check.Input
                      type="checkbox"
                      value={price}
                      onChange={handleFilterChange}
                      checked={filter.price === price}
                      id={price}
                      name="price"
                    />
                    <Form.Check.Label htmlFor={price}>
                      ${price}
                    </Form.Check.Label>
                  </Form.Check>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col lg={10} md={8} className="bg-light">
          <Row>
            {currentItems.map((productItem) => (
              <Col key={productItem.id} xl={3} lg={4} md={6} sm={6} xs={12} className="mb-4">
                <Product product={productItem} />
              </Col>
            ))}
          </Row>
          <Pagination>
            <Pagination.First onClick={() => { paginate(1); window.scrollTo(0, 0); }} />
            {currentPage > 1 && <Pagination.Prev onClick={() => { paginate(currentPage - 1); window.scrollTo(0, 0); }} />}
            {pageNumbers.slice(Math.max(0, currentPage - 4), Math.min(currentPage + 4, pageNumbers.length)).map((number) => (
              <Pagination.Item key={number} active={number === currentPage} onClick={() => { paginate(number); window.scrollTo(0, 0); }}>
                {number}
              </Pagination.Item>
            ))}
            {currentPage < pageNumbers.length && <Pagination.Next onClick={() => { paginate(currentPage + 1); window.scrollTo(0, 0); }} />}
            <Pagination.Last onClick={() => { paginate(pageNumbers.length); window.scrollTo(0, 0); }} />
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
};
