import { Button, Container } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";

export const NoDataPage = () => {
  return (
    <Container className="text-center mt-5" style={{ height: "80vh",maxWidth: '15in' , minWidth:'4in'  }}>
      <h1>404 - Page Not Found</h1>
      <Container>
        <h1>This Page is unavailable </h1>
        <Link to="/">
          <Button variant="secondary">To Home</Button>
        </Link>
      </Container>
    </Container>
  );
};
