import { db } from "../../config/firebase-config";
import { doc, updateDoc } from "firebase/firestore";

export const updateProductImage = async (id, imgname, imgurldefined) => {
  const salerefernce = doc(db, "OurCollection", id);
  const updateData = {};
  updateData[imgname] = imgurldefined;
  updateDoc(salerefernce, updateData)
    .then((response) => {
      alert(imgname + " is updated");
    })
    .catch((error) => {
      console.log(error.message);
    });
};
