import React, { useEffect, useState, useCallback } from 'react';
import { Container, Table, Row, Col, InputGroup, FormControl, Button, Dropdown } from "react-bootstrap";
import { getDocs, collection, orderBy, query } from "firebase/firestore";
import { db } from "../config/firebase-config";
import { saveAs } from 'file-saver';

export const AllOrders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filter, setFilter] = useState({ fromDate: '', toDate: '', orderNumber: '', userEmail: '' });

  const fetchOrders = useCallback(async () => {
    const queryBuilder = query(
      collection(db, "Orders"),
      orderBy("createdAt", "desc")
    );

    await getDocs(queryBuilder).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setOrders(newData);
      setFilteredOrders(newData);
    });
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    const filtered = orders.filter(order => 
      (filter.fromDate === '' || new Date(order.createdAt.toDate()) >= new Date(filter.fromDate)) &&
      (filter.toDate === '' || new Date(order.createdAt.toDate()) <= new Date(filter.toDate)) &&
      (filter.orderNumber === '' || order.orderNumber.includes(filter.orderNumber)) &&
      (filter.userEmail === '' || order.userEmail === filter.userEmail || filter.userEmail === 'all')
    );
    setFilteredOrders(filtered);
  }, [filter, orders]);

  const downloadExcel = () => {
    const excelContent = `Submit Date,Order Number,Customer Email,Product SKU,Product Name,Yardage,Price,Total\n` +
      filteredOrders.map(order => 
        `${new Date(order.createdAt.toDate()).toLocaleDateString()},${order.orderNumber},${order.userEmail},${order.productSKU},${order.productName},${order.yardage},${order.productPrice},${order.totalPrice}`
      ).join('\n');
    const blob = new Blob([excelContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, "orders.csv");
  };

  return (
    <Container className="Grid-View bg-light" key={"Sale-Container"} style={{ minHeight: "90vh", maxWidth: '15in', minWidth:'4in' }}>
      <Row className="bg-light border mb-1">
        <h1>My Orders </h1>
      </Row>
      <Row>
        <Col md={12} sm={12} className="mb-3">
          <InputGroup>
            <FormControl
              placeholder="From Date"
              aria-label="From Date"
              aria-describedby="basic-addon2"
              name="fromDate"
              value={filter.fromDate}
              onChange={handleFilterChange}
            />
            <FormControl
              placeholder="To Date"
              aria-label="To Date"
              aria-describedby="basic-addon2"
              name="toDate"
              value={filter.toDate}
              onChange={handleFilterChange}
            />
            <FormControl
              placeholder="Filter by Order Number"
              aria-label="Filter by Order Number"
              aria-describedby="basic-addon2"
              name="orderNumber"
              value={filter.orderNumber}
              onChange={handleFilterChange}
            />
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                {filter.userEmail === '' ? 'Filter by Customer Email' : filter.userEmail === 'all' ? 'Show All' : filter.userEmail}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item key="all" onClick={() => setFilter(prevState => ({ ...prevState, userEmail: 'all' }))}>
                  Show All
                </Dropdown.Item>
                {Array.from(new Set(orders.map(order => order.userEmail))).map(email => (
                  <Dropdown.Item key={email} onClick={() => setFilter(prevState => ({ ...prevState, userEmail: email }))}>
                    {email}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Button variant="outline-secondary" onClick={downloadExcel}>
              Download Excel
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Submit Date</th>
              <th>Order Number</th>
              <th>Customer Email</th>
              <th>Image</th>
              <th>Product SKU</th>
              <th>Product Name</th>
              <th>Yardage</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => (
              <tr key={order.id}>
                <td>{new Date(order.createdAt.toDate()).toLocaleDateString()}</td>
                <td>{order.orderNumber}</td>
                <td>{order.userEmail}</td>
                <td><img src={order.imgurl} alt={`Order ${order.orderNumber}`} style={{ width: '50px', height: '50px' }} /></td>
                <td>{order.productSKU}</td>
                <td>{order.productName}</td>
                <td>{order.yardage}</td>
                <td>{order.productPrice}</td>
                <td>{order.totalPrice}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};
