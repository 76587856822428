import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button, Form, InputGroup, NavItem } from "react-bootstrap";
import { useAuth } from "../../router/AuthContext";
import { useCart } from "../cart/Cart";
import CartModal from "../cart/CartModal";
import LoginModal from "./LoginModal";
import search from "../icons/search.svg";
import shoppingbag from "../icons/shoppingbag.svg";
import logo from "../images/logo.png" // This is the logo to be used for the header
function Header() {
  const [showLogin, setShowLogin] = useState(false);
  const { user, login, logout } = useAuth(); // Use the useAuth hook
  const { cartItems, removeItem, clearCart } = useCart();
  const [showCartModal, setShowCartModal] = useState(false);
  const handleCloseCartModal = () => setShowCartModal(false);
  const handleShowCartModal = () => setShowCartModal(true);

  const adminEmails = ["sunrisetextileshkltd@gmail.com", "singhrajvir456@yahoo.com"]
  const isAdmin = user && adminEmails.includes(user.email);

  const handleLogout = () => {
    logout();
    alert("You have been logged out.");
  };

  const handleLogin = () => {
    setShowLogin(true);
  };

  return (
    <>
      <Navbar variant="dark" expand="lg" bg="dark" className="d-none d-lg-block" id="templatemo_nav_top">
        <Container className="text-light">
          <Navbar.Collapse className="justify-content-between">
            <div>
              <i className="fa fa-envelope mx-2"></i>
              <Navbar.Text className="small">
                <a className="text-light text-decoration-none small" href="mailto:mike@sunrisetextileshk.com">mike@sunrisetextileshk.com</a>
              </Navbar.Text>
              <i className="fa fa-phone mx-2"></i>
              <Navbar.Text className="small">
                <a className="text-light text-decoration-none small" href="tel:2301-3536">(852) 2301 3536</a>
              </Navbar.Text>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="shadow bg-body-tertiary"
        bg="dark"
        data-bs-theme="dark"
        style={{ top: "fixed" }}
        key="navbar "
        
      >
  
          <Navbar.Brand href="/" key="navbar-brand" className="text-success logo h1 align-self-center"></Navbar.Brand>
        <Container>
          <Navbar.Brand href="/" key="navbar-brand">
            <img
              alt="HomePage"
              height="60"
              src={logo} // This is where the logo is used
              key="logo-image"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            key="navbar-toggle"
          />
          <Navbar.Collapse id="responsive-navbar-nav" key="navbar-collapse">
            <Nav className="me-auto" key="nav-links">
              <Nav.Link href="/AboutUs" key="about-us" style={{ color: "white" }}>
                About Us
              </Nav.Link>
              <Nav.Link
                href="/OurCollection"
                key="our-collection"
                style={{ color: "white" }}
              >
                Our Collection
              </Nav.Link>
              <Nav.Link
                href="/NewArrivals"
                key="new-arrivals"
                style={{ color: "white" }}
              >
                New Arrivals
              </Nav.Link>
              {isAdmin && (
                <Nav.Link href="/Admin" key="admin" style={{ color: "white" }}>
                  Admin
                </Nav.Link>
              )}
              <Nav.Link href="/Sale" key="sale" style={{ color: "white" }}>
                Sale
              </Nav.Link>
              
            </Nav>
            <Nav key="search-bar">
              <InputGroup
                className="mb-3"
                style={{
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                  paddingTop: "1rem",
                }}
                key="input-group"
              >
                <InputGroup.Text key="input-group-text">
                  <img
                    src={search}
                    style={{ color: "white" }}
                    alt="search_bar"
                    key="search-icon"
                  />
                </InputGroup.Text>
                <Form.Control
                  aria-label="Search"
                  placeholder="Enter SKU"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      const path = `/Fabric/${e.target.value}`;
                      window.location.href = path;
                    }
                  }}
                  key="search-input"
                />
              </InputGroup>
            </Nav>
            <Nav key="user-auth">
              {user ? (
                <>
                  <p
                    style={{
                      color: "white",
                      marginRight: "10px",
                      marginTop: "10px",
                    }}
                    key="user-email"
                    onClick={() => window.location.href = isAdmin ? "/AllOrders" : "/MyOrders"}
                  >
                    {user.email}
                  </p>
                  <Button
                    variant="secondary"
                    onClick={handleLogout}
                    key="logout-button"
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <NavItem key="login-item">
                  <Button
                    variant="secondary"
                    onClick={handleLogin}
                    key="login-button"
                  >
                    Login/Register
                  </Button>
                  <LoginModal
                    show={showLogin}
                    setShowLogin={setShowLogin}
                    login={login}
                    key="login-modal"
                  />{" "}
                </NavItem>
              )}
            </Nav>
            <Nav onClick={handleShowCartModal} key="cart-modal-trigger">
              <Nav.Link key="cart-button-link">
                <Button variant="secondary" key="cart-button">
                  <img
                    src={shoppingbag}
                    alt="Shopping Bag"
                    style={{ width: "24px", height: "24px" }}
                    key="shopping-bag-icon"
                  />
                  {cartItems.length}
                </Button>
              </Nav.Link>
            </Nav>
            <CartModal
              show={showCartModal}
              handleClose={handleCloseCartModal}
              cartItems={cartItems}
              removeItem={removeItem}
              clearCart={clearCart}
              key="cart-modal"
            />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;


