import React, { useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { db, storage } from "../../config/firebase-config";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const AddProductModal = ({ show, handleCloseModal }) => {
  const [productSKU, setProductSKU] = useState("");
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productColor, setProductColor] = useState("Black");
  const [productCategory, setProductCategory] = useState("Shirting");
  const [productFabricPattern, setProductFabricPattern] = useState("Checks");
  const [img, setImg] = useState("");
  const [img2, setImg2] = useState("");
  const [easyIronOption, setEasyIronOption] = useState("false");
  const [seasonOption, setSeasonOption] = useState("AllSeason");
  const [productFabric, setProductFabric] = useState("Cotton");

  const resetForm = () => {
    setProductSKU("");
    setProductName("");
    setProductPrice("");
    setProductColor("Black");
    setProductCategory("Shirting");
    setProductFabricPattern("Checks");
    setImg("");
    setImg2("");
    setEasyIronOption("false");
    setSeasonOption("AllSeason");
    setProductFabric("Cotton");
  };

  const addProduct = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, "OurCollection"), {
        productSKU: productSKU,
        productName: productName,
        productPrice: productPrice,
        productColor: productColor,
        productCategory: productCategory,
        productFabricPattern: productFabricPattern,
        imgUrl1: img,
        created: Timestamp.now().toDate(),
        EasyIronOption: easyIronOption,
        seasonOption: seasonOption,
        imgUrl2: img2,
        imgUrl3: null,
        imgUrl4: null,
        imgUrl5: null,
        numberofProductsold: 0,
        onSale: false,
        instock: true,
        productFabric: productFabric,
      });
      console.log("Document written with ID: ", docRef.id);
      alert(`${productName} is added to the Stock`);
      resetForm();
      handleCloseModal();
    } catch (e) {
      console.error("Error adding document: ", e);
      alert("Error adding document: " + e);
    }
  };

  const handleImgUpload = (e) => {
    const imgs = ref(storage, `/shirt/${productSKU}`);
    uploadBytesResumable(imgs, e.target.files[0]).then((data) => {
      getDownloadURL(data.ref).then((val) => {
        setImg(val);
      });
    });
  };

  const handleImgUpload2 = (e) => {
    const imgs2 = ref(storage, `/shirt/${productSKU}_2`);
    uploadBytesResumable(imgs2, e.target.files[0]).then((data) => {
      getDownloadURL(data.ref).then((val) => {
        setImg2(val);
      });
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        resetForm();
        handleCloseModal();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add a New Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Product SKU
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="number"
                value={productSKU}
                onChange={(e) => setProductSKU(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Product Name
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="text"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Product Price
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="number"
                value={productPrice}
                onChange={(e) => setProductPrice(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Product Color
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="select"
                value={productColor}
                onChange={(e) => setProductColor(e.target.value)}
              >
                <option value="Black">Black</option>
                <option value="Blue">Blue</option>
                <option value="Brown">Brown</option>
                <option value="Cream">Cream</option>
                <option value="Green">Green</option>
                <option value="Grey">Grey</option>
                <option value="Orange">Orange</option>
                <option value="Pink">Pink</option>
                <option value="Purple">Purple</option>
                <option value="Red">Red</option>
                <option value="White">White</option>
                <option value="Yellow">Yellow</option>
                <option value="Others">Others</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Category
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="select"
                value={productCategory}
                onChange={(e) => setProductCategory(e.target.value)}
              >
                <option value="Suiting">Suiting</option>
                <option value="Shirting">Shirting</option>
                <option value="Lining">Lining</option>
                <option value="Others">Others</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Fabric Pattern
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="select"
                value={productFabricPattern}
                onChange={(e) => setProductFabricPattern(e.target.value)}
              >
                <option value="Checks">Checks</option>
                <option value="Dobby">Dobby</option>
                <option value="Jacquard">Jacquard</option>
                <option value="Herringbone">Herringbone</option>
                <option value="Houndstooth">Houndstooth</option>
                <option value="Oxford">Oxford</option>
                <option value="Pinpoint">Pinpoint</option>
                <option value="Plain">Plain</option>
                <option value="Poplin">Poplin</option>
                <option value="Prints">Prints</option>
                <option value="Stripe">Stripe</option>
                <option value="Twill">Twill</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Season
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="select"
                value={seasonOption}
                onChange={(e) => setSeasonOption(e.target.value)}
              >
                <option value="AllSeason">All Season</option>
                <option value="SpringSummer">Spring Summer</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Fabric
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="select"
                value={productFabric}
                onChange={(e) => setProductFabric(e.target.value)}
              >
                <option value="Cotton">Cotton</option>
                <option value="Linen">Linen</option>
                <option value="Polyester">Polyester</option>
                <option value="Satin">Satin</option>
                <option value="Mix">Mix</option>
                <option value="Others">Others</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Easy Iron
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="select"
                value={easyIronOption}
                onChange={(e) => setEasyIronOption(e.target.value)}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Product Photo
            </Form.Label>
            <Col sm="8">
              <Form.Control type="file" onChange={(e) => handleImgUpload(e)} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Product Photo2
            </Form.Label>
            <Col sm="8">
              <Form.Control type="file" onChange={(e) => handleImgUpload2(e)} />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          variant="primary"
          disabled={!img || !img2}
          onClick={addProduct}
        >
          Submit
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            resetForm();
            handleCloseModal();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProductModal;
