import React, { useState } from "react";
import {
  Button,
  Modal,
  Container,
  Row,
  InputGroup,
  Form,
  Alert,
} from "react-bootstrap";
import {
  sendPasswordReset,
  registerWithEmailAndPassword,
  logInWithEmailAndPassword,
} from "../../config/firebase-config";

function LoginModal({ show, setShowLogin, login }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [mode, setMode] = useState("login"); // 'login', 'reset', 'register'

  const handleLoginClose = () => {
    setShowLogin(false);
    setMessage("");
    setMode("login");
  };

  const handleUserLogin = () => {
    logInWithEmailAndPassword(email, password)
      .then(() => {
        login({ email });
        setShowLogin(false);
        alert("You are logged in.");
      })
      .catch((error) => {
        setMessage(`Login failed: ${error.message}`);
      });
  };

  const handlePasswordReset = () => {
    sendPasswordReset(email)
      .then(() => {
        setMessage("Password reset email sent!");
      })
      .catch((error) => {
        setMessage(`Error: ${error.message}`);
      });
  };

  const handleUserRegister = () => {
    registerWithEmailAndPassword(email, password)
      .then(() => {
        setMessage("Registration successful! Please log in.");
        setShowLogin(false);
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          setMessage(
            "Error: This email is already in use. Please use a different email or reset your password."
          );
        } else {
          setMessage(`Registration failed: ${error.message}`);
        }
      });
  };

  const modalTitle = () => {
    switch (mode) {
      case "login":
        return "Login";
      case "reset":
        return "Reset Password";
      case "register":
        return "Register";
      default:
        return "Login";
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleLoginClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {mode === "login" && (
            <div>
              <Row>
                <InputGroup>
                  <InputGroup.Text id="email" style={{ width: "100px" }}>
                    Email
                  </InputGroup.Text>
                  <Form.Control
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </Row>
              <Row>
                <InputGroup>
                  <InputGroup.Text id="password" style={{ width: "100px" }}>
                    Password
                  </InputGroup.Text>
                  <Form.Control
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </Row>
            </div>
          )}
          {mode === "reset" && (
            <Row>
              <InputGroup>
                <InputGroup.Text id="email" style={{ width: "100px" }}>
                  Email
                </InputGroup.Text>
                <Form.Control
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
            </Row>
          )}
          {mode === "register" && (
            <div>
              <Row>
                <InputGroup>
                  <InputGroup.Text id="email" style={{ width: "100px" }}>
                    Email
                  </InputGroup.Text>
                  <Form.Control
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </Row>
              <Row>
                <InputGroup>
                  <InputGroup.Text id="password" style={{ width: "100px" }}>
                    Password
                  </InputGroup.Text>
                  <Form.Control
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </Row>
            </div>
          )}
          {message && <Alert variant="info">{message}</Alert>}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {mode === "login" && (
          <div>
            <Button variant="link" onClick={() => setMode("register")}>
              Register
            </Button>
            <Button variant="link" onClick={() => setMode("reset")}>
              Forgot Password?
            </Button>
            <Button variant="secondary" onClick={handleUserLogin}>
              Login
            </Button>
          </div>
        )}
        {mode === "register" && (
          <div>
            <Button variant="link" onClick={() => setMode("login")}>
              Login
            </Button>
            <Button variant="secondary" onClick={handleUserRegister}>
              Register
            </Button>
          </div>
        )}
        {mode === "reset" && (
          <div>
            <Button variant="link" onClick={() => setMode("login")}>
              Login
            </Button>
            <Button variant="secondary" onClick={handlePasswordReset}>
              Reset Password
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default LoginModal;
