import { db } from "../../config/firebase-config";
import { doc, updateDoc } from "firebase/firestore";

export const changetoSaleProduct = async (id, productName) => {
  const salerefernce = doc(db, "OurCollection", id);
  updateDoc(salerefernce, {
    onSale: true,
  })
    .then((response) => {
      alert(productName + " is on Sale");
    })
    .catch((error) => {
      console.log(error.message);
    });
};
