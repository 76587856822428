import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useCallback, useEffect, useState } from "react";
import { db } from "../../config/firebase-config";
import { collection, query, getDocs, limit, where } from "firebase/firestore";
import { Button, Container, InputGroup, Form , Carousel} from "react-bootstrap";
import { CardCarousel } from "../maincarousel/CardsCarousel";
import { useCart } from "../cart/Cart";
import ImageMagnifier from "./ImageMagnifier";



export const ProductViewCard = ({ product }) => {

  const [similarProduct, setSimilarProduct] = useState([]);
  const [yardage, setYardage] = useState();
  const [activeImage, setActiveImage] = useState(product.imgUrl1);
  const { addToCart } = useCart();

   const handleAddToCart = () => {
    if (yardage && yardage > 0) {
      addToCart(product, yardage);
      setYardage(0);
    } else {
      alert("Please enter a valid yardage greater than 0.");
    }
  };


   const fetchSimilarProduct = useCallback(async () => { // Wrap with useCallback
    const queryBuilder = query(
      collection(db, "OurCollection"),
      where("productFabricPattern", "==", product.productFabricPattern),
      where("book", "==", product.book),
      limit(10)
    );

    await getDocs(queryBuilder).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setSimilarProduct(newData);
    });
  }, [product.productFabricPattern,product.book]); // Dependency array for useCallback


  useEffect(() => {
    fetchSimilarProduct();
  }, [fetchSimilarProduct]);

  let cardText = "";
  if (product.EasyIronOption === "true") {
    cardText = "Easy Iron";
  } else {
    cardText = "Non Easy Iron";
  }
  return (
    <Container className="bg-light">
            <Container className="pb-5">
                <Row>
                    <Col lg={5} className="mt-5">
                        <Card className="mb-3">
                            <ImageMagnifier
                                src={activeImage}
                                alt={product.productName}
                                width="100%"
                                height="auto"
                                magnifierHeight={150}
                                magnifieWidth={150}
                                zoomLevel={2}
                            />
                        </Card>
                       <Row> 
                            {/* Start Carousel Wrapper */}
                            <Col id="multi-item-example" className="carousel slide carousel-multi-item" data-bs-ride="carousel" xs={10}>
                                {/* Start Slides */}
                                <Carousel interval={null} indicators={false}>
                                    <Carousel.Item>
                                        <div className="d-flex">
                                            {[product.imgUrl1, product.imgUrl2, product.imgUrl3].filter(url => url).map((url, index) => (
                                                <button className="d-block w-100" onClick={() => setActiveImage(url)} key={index} style={{ border: 'none', background: 'none', padding: 0 }}>
                                                    <img className="img-fluid" src={url} alt={`Product ${index + 1}`} />
                                                </button>
                                            ))}
                                        </div>
                                    </Carousel.Item>
                                    {product.imgUrl4 || product.imgUrl5 ? (
                                        <Carousel.Item>
                                            <div className="d-flex">
                                                {[product.imgUrl4, product.imgUrl5].filter(url => url).map((url, index) => (
                                                    <button className="d-block w-50" onClick={() => setActiveImage(url)} key={index} style={{ border: 'none', background: 'none', padding: 0 }}>
                                                        <img className="img-fluid" src={url} alt={`Product ${index + 4}`} />
                                                    </button>
                                                ))}
                                            </div>
                                        </Carousel.Item>
                                    ) : null}
                                </Carousel>

                            </Col>

                          
                        </Row>
                    </Col>
                    {/* col end */}
                    <Col lg={7} className="mt-5">
                        <Card>
                            <Card.Body>
                                <h3 className="h3">{product.productName}</h3>
                                <p className="h4 py-2">{product.productSKU}</p>
                                <Row>
                                    <Col><h6>Price:</h6></Col>
                                    <Col><p className="text-muted"><strong>${product.productPrice}</strong></p></Col>
                                </Row>
                                <Row>
                                    <Col><h6>Fabric:</h6></Col>
                                    <Col><p className="text-muted"><strong>{product.productFabric}</strong></p></Col>
                                </Row>
                                <Row>
                                    <Col><h6>Color:</h6></Col>
                                    <Col><p className="text-muted"><strong>{product.productColor}</strong></p></Col>
                                </Row>
                                <Row>
                                    <Col><h6>Category:</h6></Col>
                                    <Col><p className="text-muted"><strong>{product.productCategory}</strong></p></Col>
                                </Row>
                                <Row>
                                    <Col><h6>Fabric Pattern:</h6></Col>
                                    <Col><p className="text-muted"><strong>{product.productFabricPattern}</strong></p></Col>
                                </Row>
                                <Row>
                                    <Col><h6>Season:</h6></Col>
                                    <Col><p className="text-muted"><strong>{product.seasonOption}</strong></p></Col>
                                </Row>
                                <Row>
                                    <Col><h6>Ironing:</h6></Col>
                                    <Col><p className="text-muted"><strong>{cardText}</strong></p></Col>
                                </Row>
                                <Row>
                                    <Col style={{ color: !product.instock ? "red" : "inherit" }}>
                                        {!product.instock && <strong>Out of Stock</strong>}
                                    </Col>
                                </Row>
                                {product.instock && (
                                    <Form>
                                        <input type="hidden" name="product-title" value={product.productName} />
                                        <Row className="pb-3">
                                            <Col className="d-grid">
                                                <InputGroup>
                                                    <Form.Control
                                                        type="float"
                                                        placeholder="Enter yardage"
                                                        value={yardage}
                                                        onChange={(e) => setYardage(e.target.value)}
                                                    />
                                                    <Button 
                                                        className="btn-lg" 
                                                        onClick={handleAddToCart} 
                                                        disabled={!yardage || yardage <= 0}
                                                    >
                                                        Add To Cart
                                                    </Button>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </ Container>
            <Container className="py-5">
                <Row className="text-left p-2 pb-3">
                    <h4>Related Products</h4>
                </Row>
                <Row id="carousel-related-product">
                     <CardCarousel products={similarProduct}  style={{
       
            margin: "1rem",
          }}/>
                </Row>
            </Container>
    </Container>

  );
};
