import React, { useState } from "react";
import { Modal, Button, Form, Table, InputGroup } from "react-bootstrap";
import { useCart } from "./Cart";
import { useAuth } from "../../router/AuthContext";
import emailjs from "emailjs-com";
import { db } from "../../config/firebase-config";
import {
  collection, addDoc,getDocs,
  query,
  where,
  setDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";


const CartModal = ({ show, handleClose, cartItems, removeItem, clearCart }) => {
  const { updateYardage } = useCart();
  const { user } = useAuth();
  const [orderNumber, setOrderNumber] = useState("");

  const sendEmail = async () => {
    if (!orderNumber) {
      alert("Please enter an order number.");
      return;
    }
    if (!user) {
      alert("Please login to proceed.");
      return;
    }
    emailjs.init("esjKwBAdG1sZtzBbJ");
    emailjs
      .send("service_hqslijq", "template_a3amfoj", {
        to_email: "order@sunrisetextileshk.ltd",
        from_email: user ? user.email : "Unknown User",
        order_number: orderNumber,
        message_html: `
          <table border="1" cellpadding="5" cellspacing="0">
            <tr>
              <th>Name</th>
              <th>SKU</th>
              <th>Yardage</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
            ${cartItems.map((item) => `
              <tr>
                <td>${item.name}</td>
                <td>${item.sku}</td>
                <td>${item.yardage}</td>
                <td>${item.price}</td>
                <td>${Math.round((item.price*item.yardage) * 100) / 100}</td>
              </tr>
            `).join('')}
            <tr>
              <td colspan="4" style="text-align: right;">Total:</td>
              <td>${cartItems.reduce((acc, item) => acc + (item.price * item.yardage), 0).toFixed(2)}</td>
            </tr>
          </table>
        `,
      })
      .then(
        async function (response) {
          console.log("Email sent successfully:", response);
          alert("Email successfully sent!");
          for (const item of cartItems) {
            const docSnap = query(
              collection(db, "OurCollection"),
              where("productSKU", "==", item.sku)
            );
            const newData = await getDocs(docSnap).then((querySnapshot) => {
              return querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
              }));
            });
            newData.forEach(async (product) => {
              await setDoc(
                doc(db, "OurCollection", product.id),
                {
                  numberofProductsold: product.numberofProductsold + 1,
                },
                { merge: true }
              );
            });
          }

          // Add order details to Firebase before clearing cart
          for (const item of cartItems) {
            try {
              await addDoc(collection(db, "Orders"), {
                orderNumber: orderNumber,
                productSKU: item.sku,
                yardage: item.yardage,
                productName: item.name,
                userEmail: user.email,
                productPrice:item.price,
                totalPrice: Math.round((item.price*item.yardage) * 100) / 100,
                imgurl:item.imgurl,
                createdAt: serverTimestamp(),
              });

              // console.log("Document written with ID: ", docRef.id);
              // alert(`${item.name} is added to the Stock`);
            } catch (e) {
              // console.error("Error adding document: ", e);
              // alert("Error adding document: " + e);
            }
          }

          // Clear cart items after sending the email
          clearCart();
          setOrderNumber("");
          handleClose();

        },
        function (error) {
          console.error("Failed to send email:", error);
          alert("Failed to send email: " + error.text);
        }
      );
  };

  const totalSum = cartItems.reduce(
    (acc, item) => acc + item.price * item.yardage,
    0
  );

  const handleYardageChange = (index, newYardage) => {
    updateYardage(index, parseFloat(newYardage));
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Your Cart ({cartItems.length}{" "}
          {cartItems.length === 1 ? "Item" : "Items"})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {cartItems.length > 0 ? (
          <div className="untree_co-section before-footer-section">
            <div className="container">
              <div className="row mb-5">
                <Form className="col-md-12" method="post">
                  <div className="site-blocks-table">
                    <Table striped>
                      <thead>
                        <tr>
                          <th className="product-thumbnail"> </th>
                          <th className="product-name">Product</th>
                          <th className="product-price">Price</th>
                          <th className="product-quantity">Yardage</th>
                          <th className="product-total">Total</th>
                          <th className="product-remove">Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartItems.map((item, index) => (
                          <tr key={index}>
                            <td className="product-thumbnail">
                              <img
                                src={item.imgurl}
                                alt={item.name}
                                className="img-fluid"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            </td>
                            <td className="product-name">
                              <h2 className="h6 text-black">{item.name}</h2>
                            </td>
                            <td>${item.price}</td>
                            <td>
                              <InputGroup
                                className="mb-3 d-flex align-items-center quantity-container"
                                style={{ maxWidth: "120px" }}
                              >
                                <Form.Control
                                  type="number"
                                  className="form-control text-center quantity-amount"
                                  value={item.yardage}
                                  onChange={(e) =>
                                    handleYardageChange(index, e.target.value)
                                  }
                                />
                              </InputGroup>
                            </td>
                            <td>${(item.price * item.yardage).toFixed(2)}</td>
                            <td>
                              <Button
                                variant="dark"
                                className="btn-sm"
                                onClick={() => removeItem(index)}
                              >
                                X
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Form>
              </div>
              <div className="row justify-content-end">
                <div className="col-auto">
                  <span className="text-black">Total:</span>
                  <strong className="text-black ml-2">
                    ${totalSum.toFixed(2)}
                  </strong>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <Form.Control
                    type="text"
                    placeholder="Enter Order Number"
                    value={orderNumber}
                    onChange={(e) => setOrderNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>Your cart is empty.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={clearCart}>
          Clear Cart
        </Button>
        <Button variant="primary" onClick={sendEmail}>
          Send Order
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CartModal;
