import React, { useState, useEffect } from "react";
import MainCarousel from "../components/maincarousel/MainCarousel";
import { db } from "../config/firebase-config";
import { getDocs, collection, query, orderBy, limit } from "firebase/firestore";
import { CardCarousel } from "../components/maincarousel/CardsCarousel";
import { Container, Row, Col } from "react-bootstrap";
import compantlionlogo from "../components/images/Company_Logo.png";
import { Carousel } from "react-bootstrap";

export const Home = () => {
  const [newproducts, setnewproducts] = useState([]);
  const [newArrivalProduct, setnewArrivalProduct] = useState([]);
  const [uniqueBooks, setuniqueBooks] = useState([]);
  const [newproductstoshow, setnewproductstoshow] = useState([]);
  const [hotSaleProductsToShow, sethotSaleProductsToShow] = useState([]);


  const fetchPost = async () => {
    const snapshot = await getDocs(collection(db, "OurCollection"), orderBy("created", "desc"));
    const newData = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setnewproducts(newData);
  };
  useEffect(() => {
    if (newproducts.length > 0) {
      const uniqueBooks = [
        ...new Set(newproducts.map((product) => product.book)),
      ].sort();
      setuniqueBooks(uniqueBooks);
    }
  }, [newproducts]);


  const fetchproductstoptoshow = async () => {
    const querryBuilder = query(
      collection(db, "OurCollection"),
      limit(25)
    );
    const snapshot = await getDocs(querryBuilder);
    const newData = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setnewproductstoshow(newData);
  };



  const fetchproductstop3 = async () => {
    const querryBuilder = query(
      collection(db, "OurCollection"),
      orderBy("created", "desc"),
      limit(20)
    );
    const snapshot = await getDocs(querryBuilder);
    const newData = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setnewArrivalProduct(newData);
  };
  
  const fetchhotSaleProductsToShow = async () => {
    const querryBuilder = query(
      collection(db, "OurCollection"),
      orderBy("numberofProductsold", "desc"),
      limit(20)
    );
    const snapshot = await getDocs(querryBuilder);
    const newData = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    sethotSaleProductsToShow(newData);
  };


  useEffect(() => {
    fetchPost();
    fetchproductstop3();
    fetchproductstoptoshow();
    fetchhotSaleProductsToShow();
  }, []);

  return (
    <Container fluid>
      <Row>
        <MainCarousel />
      </Row>
      <Container style={{  maxWidth: '15in' , minWidth:'4in' }}>
        <Row className="text-center pt-3 " >
          <Col lg={6} className="m-auto">
            <h1 className="h1">
              <u>
                <strong>One Stop</strong> Fabric Hub
              </u>
            </h1>
            <p>
              Your ultimate destination for a wide variety of premium fabrics,
              offering a diverse collection to fulfill all your fabric needs
              with style and quality.
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={4} className="p-5 mt-2">
            <a href="/OurCollection">
              <img
                src="https://4.imimg.com/data4/YD/VA/MY-13392575/cotton-shirting-fabrics-500x500.jpg"
                alt="Our Collection"
                className="rounded-circle img-fluid border"
              />
            </a>
            <h5 className="text-center mt-3 mb-1">Our Collection</h5>
          </Col>
          <Col xs={12} md={4} className="p-5 mt-2">
            <a href="/NewArrivals">
              <img
                src="https://5.imimg.com/data5/SELLER/Default/2020/12/UB/WS/ZX/3126540/cotton-shirting-fabric.jpg"
                alt="New Arrivals"
                className="rounded-circle img-fluid border"
              />
            </a>
            <h2 className="h5 text-center mt-3 mb-1">New Arrivals</h2>
          </Col>
          <Col xs={12} md={4} className="p-5 mt-2">
            <a href="/Sale">
              <img
                src="https://5.imimg.com/data5/KD/YI/LO/SELLER-899056/printed-cotton-shirt-fabric.jpg"
                alt="Sale"
                className="rounded-circle img-fluid border"
              />
            </a>
            <h2 className="h5 text-center mt-3 mb-1">Sale</h2>
          </Col>
        </Row>
      </Container>

      <Row className="bg-light border">
        <Col xs={12} className="text-center">
          <img
            src={compantlionlogo}
            alt="compant_lionlogo"
            className="img-fluid"
          />
        </Col>
      </Row>
      <Row>
        <Container style={{  maxWidth: '15in' , minWidth:'4in' }}>
          <Row>
            <Row className="mt-1">
              <h5>
                <strong>Our Collection books</strong>
              </h5>
            </Row>
          </Row>
          <Carousel
            interval={null}
            indicators={true}
            data-bs-theme="dark"
            wrap={false}
            controls={false}
          >
            {uniqueBooks
              .reduce((result, book, index) => {
                const chunkIndex = Math.floor(index / 4); // Grouping 5 books per slide

                if (!result[chunkIndex]) {
                  result[chunkIndex] = []; // start a new chunk
                }

                result[chunkIndex].push(
                  <Col key={index} xs={6} md={3} className="p-3 mt-2">
                    <a href={`/OurCollection?book=${book || "Others"}`}>
                      <img
                        src="https://www.shivasfabrics.com/cdn/shop/products/leonardo-cotton-shirting-fabric-swatchbook-swbk-ls-cs-002_product-1_850x.jpg?v=1679796558"
                        alt={book}
                        className="rounded-circle img-fluid border"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </a>
                    <h5 className="text-center mt-1 mb-1">
                      {book || "Others"}
                    </h5>
                  </Col>
                );

                return result;
              }, [])
              .map((group, index) => (
                <Carousel.Item key={index}>
                  <Row>{group}</Row>
                </Carousel.Item>
              ))}
          </Carousel>
        </Container>
      </Row>
      <Row>
        <Container className="bg-light border">
          <Row className="mt-1">
            {" "}
            <h5>
              <strong>Our Collections</strong>
            </h5>
          </Row>
          <Row>
            <CardCarousel products={newproductstoshow} />
          </Row>
        </Container>
      </Row>
      <Row>
        <Container>
          <Row className="mt-1">
            {" "}
            <h5>
              <strong>New Arrivals</strong>
            </h5>
          </Row>
          <Row>
            <CardCarousel products={newArrivalProduct} />
          </Row>
        </Container>
      </Row>
      <Row>
        <Container className="bg-light border">
          <Row className="mt-1">
            {" "}
            <h5>
              <strong>Hot Selling Items</strong>
            </h5>
          </Row>
          <Row>
            <CardCarousel products={hotSaleProductsToShow} />
          </Row>
        </Container>
      </Row>
    </Container>
  );
};
