import { db } from "../../config/firebase-config";
import { doc, updateDoc, Timestamp } from "firebase/firestore";

export const updateProductDetails = async (
  id,
  productSKU,
  productName,
  productPrice,
  productColor,
  productCategory,
  productFabricPattern,
  EasyIronOption,
  seasonOption,
  productFabric
) => {
  const salerefernce = doc(db, "OurCollection", id);
  updateDoc(salerefernce, {
    productSKU: productSKU,
    productName: productName,
    productPrice: productPrice,
    productColor: productColor,
    productCategory: productCategory,
    productFabricPattern: productFabricPattern,
    EasyIronOption: EasyIronOption,
    seasonOption: seasonOption,
    productFabric:productFabric,
    updated: Timestamp.now().toDate(),
  })
    .then((response) => {
      alert(productName + " is updated ");
    })
    .catch((error) => {
      console.log(error.message);
    });
};
