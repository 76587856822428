import React, { useEffect, useState, useCallback } from 'react';
import { Container, Table, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { getDocs, collection, where, orderBy, query } from "firebase/firestore";
import { db } from "../config/firebase-config";
import { useAuth } from "../router/AuthContext";

export const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const { user } = useAuth();
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filter, setFilter] = useState({ submitDate: '', orderNumber: '' });

  const fetchOrders = useCallback(async () => {

    const queryBuilder = query(
      collection(db, "Orders"),
      where("userEmail", "==", user.email),
      orderBy("createdAt", "desc")
    );

    await getDocs(queryBuilder).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setOrders(newData);
      setFilteredOrders(newData);
    });
  }, [user.email]); // Added user.email as a dependency to useCallback

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]); // Added fetchOrders as a dependency to useEffect

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    const filtered = orders.filter(order => 
      (filter.submitDate === '' || new Date(order.createdAt.toDate()).toLocaleDateString() === filter.submitDate) &&
      (filter.orderNumber === '' || order.orderNumber.includes(filter.orderNumber))
    );
    setFilteredOrders(filtered);
  }, [filter, orders]);

  return (
    <Container className="Grid-View bg-light" key={"Sale-Container"} style={{ minHeight: "90vh", maxWidth: '15in', minWidth:'4in' }}>
      <Row className="bg-light border mb-1">
        <h1>My Orders </h1>
      </Row>
      <Row>
        <Col md={12} sm={12} className="mb-3">
          <InputGroup>
            <FormControl
              placeholder="Filter by Submit Date"
              aria-label="Filter by Submit Date"
              aria-describedby="basic-addon2"
              name="submitDate"
              value={filter.submitDate}
              onChange={handleFilterChange}
            />
            <FormControl
              placeholder="Filter by Order Number"
              aria-label="Filter by Order Number"
              aria-describedby="basic-addon2"
              name="orderNumber"
              value={filter.orderNumber}
              onChange={handleFilterChange}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Submit Date</th>
              <th>Order Number</th>
              <th>Image</th>
              <th>Product SKU</th>
              <th>Product Name</th> {/* Added Product Name column */}
              <th>Yardage</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => (
              <tr key={order.id}>
                <td>{new Date(order.createdAt.toDate()).toLocaleDateString()}</td> {/* Corrected submit date formatting */}
                <td>{order.orderNumber}</td>
                <td><img src={order.imgurl} alt={`Order ${order.orderNumber}`} style={{ width: '50px', height: '50px' }} /></td>
                <td>{order.productSKU}</td>
                <td>{order.productName}</td> {/* Added Product Name column */}
                <td>{order.yardage}</td>
                <td>{order.productPrice}</td>
                <td>{order.totalPrice}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};
