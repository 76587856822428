import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Container } from "react-bootstrap";
import { ProductCardCarousel as Product } from "../productcard/ProductCardCarousel";
import { useMediaQuery } from "react-responsive";

const reduceProducts = (products, itemsPerGroup) => {
  return products.reduce((acc, cur, index) => {
    const groupIndex = Math.floor(index / itemsPerGroup);
    if (!acc[groupIndex]) acc[groupIndex] = [];
    acc[groupIndex].push(cur);
    return acc;
  }, []);
};

export const CardCarousel = ({ products }) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [groupedProducts, setGroupedProducts] = useState([]);

  const isLarge = useMediaQuery({ query: "(min-width: 992px)" });
  const isMedium = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 991px)",
  });
  const isSmall = useMediaQuery({ query: "(max-width: 767px)" });

  useEffect(() => {
    const itemsPerGroup = isLarge ? 5 : isMedium ? 3 : 1;
    setGroupedProducts(reduceProducts(products, itemsPerGroup));
  }, [isLarge, isMedium, isSmall, products]);

  const handleSelect = (selectedIndex) => {
    setCarouselIndex(selectedIndex);
  };

  return (
    <Carousel
      interval={null}
      indicators={false}
      data-bs-theme="dark"
      activeIndex={carouselIndex}
      onSelect={handleSelect}
    >
      {groupedProducts.map((group, index) => (
        <Carousel.Item key={index}>
          <Container className="d-flex justify-content-center">
            {group.map((product, idx) => (
              <Product key={idx} product={product} />
            ))}
          </Container>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
