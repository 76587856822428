import React, { useEffect, useState } from "react";
import { db } from "../config/firebase-config";
import { collection, query, getDocs, where } from "firebase/firestore";
import { Product } from "../components/productcard/ProductCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

export const Sale = () => {
  const [queryProduct, setQueryProduct] = useState([]);

  const fetchSaleProducts = async () => {
    const queryBuilder = query(
      collection(db, "OurCollection"),
      where("onSale", "==", true)
    );

    const querySnapshot = await getDocs(queryBuilder);
    const newData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setQueryProduct(newData);
  };

  useEffect(() => {
    fetchSaleProducts();
  }, []);

  return (
    <Container className="Grid-View bg-light" key={"Sale-Container"} style={{ minHeight: "90vh", maxWidth: '15in' , minWidth:'4in'  }}>
      <Row key={"header-Row"}>
        <h2>Sale</h2>
      </Row>
      <Row className="Grid-View-Row" key={"data-Row"}>
        {queryProduct.map((productItem) => (
          <Col
            key={productItem.productSKU}
            xs={{ span: 6 }}
            sm={{ span: 5 }}
            md={{ span: 4 }}
            lg={{ span: 3 }}
            xl={{ span: 2 }}
          >
            <Product key={productItem.productSKU} product={productItem} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
