import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Container, Modal, Form } from "react-bootstrap";
import { useState } from "react";
import { deleteproduct } from "../adminFucntions/DeleteProductID";
import { changetoSaleProduct } from "../adminFucntions/ChangetoSaleProduct";
import { outofStockProduct } from "../adminFucntions/OutofStockProduct";
import { removeSaleProduct } from "../adminFucntions/RemoveSaleProduct";
import { inStockProductset } from "../adminFucntions/InStockProductset";
import { updateProductDetails } from "../adminFucntions/UpdateProductDetails";
import { updateProductImage } from "../adminFucntions/UpdateProductImage"; // Assuming this function is defined to handle image updates
import EditProductModal from "../adminFucntions/EditProductModal";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebase-config";

export const ProductAdminCard = ({ product }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showImageEditModal, setShowImageEditModal] = useState(false);
  const [newImageUrls, setNewImageUrls] = useState({});

  const handleShowEdit = () => setShowEditModal(true);
  const handleCloseEdit = () => setShowEditModal(false);
  const handleShowImageEdit = () => setShowImageEditModal(true);
  const handleCloseImageEdit = () => setShowImageEditModal(false);

  const handleImgUpload = (e, imgName) => {
    const randomRefName = Math.random().toString(36).substring(2, 15); // Create a random string for the reference name
    const imgs = ref(storage, `/shirt/${product.productSKU}_${randomRefName}`);
    uploadBytesResumable(imgs, e.target.files[0]).on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.error("Upload failed", error);
      },
      () => {
        getDownloadURL(imgs).then((downloadURL) => {
          setNewImageUrls((prevUrls) => ({
            ...prevUrls,
            [imgName]: downloadURL,
          }));
          console.log("File available at", downloadURL);
        });
      }
    );
  };

  const [productDetails, setProductDetails] = useState({
    productSKU: product.productSKU,
    productName: product.productName,
    productPrice: product.productPrice,
    productColor: product.productColor,
    productCategory: product.productCategory,
    productFabricPattern: product.productFabricPattern,
    EasyIronOption: product.EasyIronOption,
    seasonOption: product.seasonOption,
  });

  let cardText = "";
  if (product.EasyIronOption === "true") {
    cardText = "Easy Iron";
  } else {
    cardText = "Non Easy Iron";
  }

  let onSale = "";
  if (product.onSale === true) {
    onSale = "On Sale";
  } else {
    onSale = "Not On Sale";
  }

  let instockproduct = "";
  if (product.instock === true) {
    instockproduct = "In Stock";
  } else {
    instockproduct = "Out of Stock";
  }

  return (
    <Container className="admin-product-Card">
      <Card
        className="bg-dark text-white card-horizontal mb-3"
        style={{
          margin: "1rem",
          Height: "5rem",
        }}
      >
        <Row id="row">
          <Col id="image" xs={12} sm={4} md={3}>
            <Card.Img
              className="img-square-wrapper"
              src={product.imgUrl1 || product.imgUrl2}
              style={{ Height: "15rem", width: "15rem" }}
              onClick={handleShowImageEdit}
            />
            <Modal show={showImageEditModal} onHide={handleCloseImageEdit}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Product Images</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {["imgUrl1", "imgUrl2", "imgUrl3", "imgUrl4", "imgUrl5"].map(
                  (imgName, index) => (
                    <Row key={index} className="justify-content-center">
                      <Col>
                        <img
                          src={product[imgName]}
                          alt={product[imgName]}
                          style={{
                            height: "10rem",
                            width: "10rem",
                            margin: "10px",
                          }}
                        />
                      </Col>
                      <Col>
                        <Form>
                          <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Upload New Image</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={(e) => handleImgUpload(e, imgName)}
                            />
                          </Form.Group>
                          {newImageUrls[imgName] && (
                            <Button
                              onClick={() =>
                                updateProductImage(
                                  product.id,
                                  imgName,
                                  newImageUrls[imgName]
                                )
                              }
                            >
                              Upload
                            </Button>
                          )}
                        </Form>
                      </Col>
                    </Row>
                  )
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseImageEdit}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Row>
              <Card.Title>{product.productName}</Card.Title>
            </Row>
            <Row>
              <Col>
                <Card.Body>
                  <Card.Text>SKU: {product.productSKU}</Card.Text>
                  <Card.Text>Price: {product.productPrice}</Card.Text>
                  <Card.Text>Color: {product.productColor}</Card.Text>
                  <Card.Text>Category: {product.productCategory}</Card.Text>
                  <Card.Text>Season: {product.seasonOption}</Card.Text>
                </Card.Body>
              </Col>
              <Col>
                <Card.Body>
                    <Card.Text>
                    Fabric: {product.productFabric}
                  </Card.Text>
                  <Card.Text>
                    FabricPattern: {product.productFabricPattern}
                  </Card.Text>
                  <Card.Text>
                    Created on: {product.created.toDate().toDateString()}
                  </Card.Text>
                  <Card.Text>{cardText}</Card.Text>
                  <Card.Text>{onSale}</Card.Text>
                  <Card.Text>{instockproduct}</Card.Text>
                </Card.Body>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={3}>
            <Container>
              <Row>
                <Button
                  variant="danger"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you want to delete ${product.productName}?`
                      )
                    ) {
                      deleteproduct(product.id, product.productName);
                    }
                  }}
                >
                  Remove
                </Button>
              </Row>
              <Row>
                <Button variant="info" onClick={handleShowEdit}>
                  Edit
                </Button>
                <EditProductModal
                  show={showEditModal}
                  handleClose={handleCloseEdit}
                  product={product}
                  productDetails={productDetails}
                  setProductDetails={setProductDetails}
                  updateProductDetails={updateProductDetails}
                />
              </Row>
              <Row>
                <Button
                  variant="light"
                  onClick={() =>
                    changetoSaleProduct(product.id, product.productName)
                  }
                >
                  On Sale
                </Button>
              </Row>
              <Row>
                <Button
                  variant="secondary"
                  onClick={() =>
                    removeSaleProduct(product.id, product.productName)
                  }
                >
                  Out of Sale
                </Button>
              </Row>
              <Row>
                <Button
                  variant="success"
                  onClick={() =>
                    inStockProductset(product.id, product.productName)
                  }
                >
                  In Stock
                </Button>
              </Row>
              <Row>
                <Button
                  variant="primary"
                  onClick={() =>
                    outofStockProduct(product.id, product.productName)
                  }
                >
                  Out of Stock
                </Button>
              </Row>
            </Container>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};
