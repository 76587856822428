import { Home } from "../pages/Home.js";
import { OurCollection } from "../pages/OurCollection.js";
import { Admin } from "../pages/Admin.js";
import { AboutUs } from "../pages/AboutUs.js";
import { NewArrivals } from "../pages/NewArrivals.js";
import { Routes, Route } from "react-router-dom";
import Header from "../components/header/Header.js";
import Footer from "../components/footer/Footer.js";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { ProductView } from "../pages/ProductView.js";
import { NoDataPage } from "../pages/NoDataPage.js";
import { CartProvider } from "../components/cart/Cart.js";
import { useAuth } from "./AuthContext.js";
import { Sale } from "../pages/Sale.js";
import { MyOrders } from "../pages/MyOrders.js";
import { AllOrders } from "../pages/AllOrders.js"


export default function Router() {
  const { user } = useAuth(); // Use the useAuth hook
  const adminEmails = ["sunrisetextileshkltd@gmail.com", "singhrajvir456@yahoo.com"];
  const isAdmin = user && adminEmails.includes(user.email);

  const BrowserRoutes = () => {
    return (
      <Container
        className="main-content"
        fluid
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          
        }}
      >
        <CartProvider>
          <Row>
            <Header />
          </Row>
          <Row>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/AboutUs" element={<AboutUs />} />
              <Route path="/Sale" element={<Sale />} />
              {isAdmin && <Route path="/Admin" element={<Admin />} />}
              <Route path="/OurCollection" element={<OurCollection />} />
              <Route path="/NewArrivals" element={<NewArrivals />} />
              <Route path="/Fabric/:productSKU" element={<ProductView />} />
              {isAdmin ? <Route path="/AllOrders" element={<AllOrders />} /> : user && <Route path="/MyOrders" element={<MyOrders />} />}
              <Route path="*" element={<NoDataPage />} />
            </Routes>
          </Row>
          <Row>
            <Footer />
          </Row>
        </CartProvider>
      </Container>
    );
  };

  return <BrowserRoutes />;
}
