import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics"; // Included getAnalytics
import { getPerformance } from "firebase/performance";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD-4EghHEMLWsGadHRnnCVhdISY72tK4qs",
  authDomain: "sunrisetextilehk.firebaseapp.com",
  databaseURL:
    "https://sunrisetextilehk-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "sunrisetextilehk",
  storageBucket: "sunrisetextilehk.appspot.com",
  messagingSenderId: "394718775260",
  appId: "1:394718775260:web:5f102aa2d090332e483119",
};

// Initialize Firebase App

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const auth = getAuth(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app); // Analytics initialized and exported
export const logInWithEmailAndPassword = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const registerWithEmailAndPassword = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth)
    .then(() => {
      console.log("Sign-out successful");
    })
    .catch((error) => {
      console.log(error);
    });
};
// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app);
export const trackPerformance = () => {
  perf().logEvent("app_performance");
};




export { logout, signInWithEmailAndPassword, sendPasswordReset, auth };
